.App {
  text-align: center;
}

html{

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {

  }
}

.App-header {
  /*min-height: 100vh;*/
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

 .asta-running td .table-auction{
   background-color: #ccc !important;
   cursor: default !important;
 }
